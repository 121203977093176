

//gtag('consent','update', {'analytics_storage':'granted'})};
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
//import { Link } from 'react-router-dom'
import Api from '../Api/Api'
//import { useNavigate  } from 'react-router-dom'


const CookieConsent = () => {
  const intl = useIntl()
  const [show, setShow] = useState(true);
  //let navigate = useNavigate();    

  const callC = (consent) => {
    var id = 0;
    if (localStorage.getItem("C")){
      id = localStorage.getItem("C");
    }
    Api.post('/c', { "id": id, "consent": consent }, { validateStatus: false})
    .then( (response) => { 
      //console.log(response.data.data);
      localStorage.setItem("C", response.data.data.id);
      
    }).catch((error)=>{
      console.log(error)        
    })
  }

  useEffect(() => {
    
    /*Api.post('/c', { "id": 0, "consent": null }, { validateStatus: false})
    .then( (response) => { 
      console.log(response.data.data);
    }).catch((error)=>{
      console.log(error)        
    })*/
    var r = getCookie("consent");
    if (r === "true" || r === "false"){
      setShow(false);
      if (r === "true"){      
          window.dataLayer.push('consent','update', {'analytics_storage':'granted'});
          window.dataLayer.push('consent','update', {'ad_storage':'granted'});
          window.dataLayer.push('consent','update', {'functionality_storage':'granted'});
          window.dataLayer.push('consent','update', {'personalization_storage':'granted'});
          window.dataLayer.push('consent','update', {'security_storage':'granted'});
      }
    }else{
      callC(null);
    }
  }, []); 

  const getCookie = (name) => {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const setCookie = (value) => {
    callC(value?1:0);
    setShow(false);
    if (value){
      document.cookie = `consent=true; path=/; max-age=${60 * 60 * 24 * 365};`;
      document.cookie = `consent_m=true; path=/; max-age=${60 * 60 * 24 * 365};`;
      window.dataLayer.push('consent','update', {'analytics_storage':'granted'});
      window.dataLayer.push('consent','update', {'ad_storage':'granted'});
      window.dataLayer.push('consent','update', {'functionality_storage':'granted'});
      window.dataLayer.push('consent','update', {'personalization_storage':'granted'});
      window.dataLayer.push('consent','update', {'security_storage':'granted'});
    } else {
      document.cookie = `consent=false; path=/; max-age=${60 * 60 * 24 * 1};`;
      document.cookie = `consent_m=false; path=/; max-age=${60 * 60 * 24 * 365};`;
      window.dataLayer.push('consent','update', {'analytics_storage':'denied'});
      window.dataLayer.push('consent','update', {'ad_storage':'denied'});
      window.dataLayer.push('consent','update', {'functionality_storage':'granted'});
      window.dataLayer.push('consent','update', {'personalization_storage':'denied'});
      window.dataLayer.push('consent','update', {'security_storage':'denied'});
    }
  }  

  return <>{show?
    <div className="cookie-consent">        
        <p>{intl.formatMessage({ id: "app.cookies.weuse2" })}
        <br/>{intl.formatMessage({ id: "app.cookies.formore" })} <a href={process.env.REACT_APP_URL+"?page=cookie-policy"}>{intl.formatMessage({ id: "app.footer.cookies" })}</a></p>
      <div className="buttons" >
        <button className="btn negative" onClick={ ()=> { window.location.href=process.env.REACT_APP_URL+"?page=cookie-panel";/*navigate("/?page=cookie-panel")*/ }}>{intl.formatMessage({ id: "app.cookies.configpanel" })}</button>
        <button className="btn" onClick={ ()=> { setCookie(false); window.location.reload(); } }>{intl.formatMessage({ id: "app.cookies.notaccept" })}</button>
        <button className="btn" onClick={ ()=> { setCookie(true); window.location.reload(); } }>{intl.formatMessage({ id: "app.cookies.accept" })}</button>
      </div>
    </div>:null}
  </>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops)(CookieConsent)